import React from 'react';
import useCountUp from '../hooks/useCountUp'; 
import './NumberBar.css'; // Ensure this path matches your file structure

// The NumberBar component to display dynamic count-up numbers
const NumberBar = ({ data }) => {
  return (
    <div className="number-bar">
      {data.map((item) => (
        <NumberItem key={item._id} name={item.name} value={item.value} />
      ))}
    </div>
  );
};

const NumberItem = ({ name, value }) => {
  // Use the custom hook for counting up numbers, adjust durations as needed
  const countUpValue = useCountUp(0, parseInt(value), 2000); // You can adjust duration if needed

  // Render the individual number item
  return (
    <div className="number-item">
      <span>{countUpValue}+</span>
      <label>{name}</label>
    </div>
  );
};

export default NumberBar;
