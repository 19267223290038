import React, { useEffect, useRef, useState } from "react";
import styles from "./Home.module.css";
import NumberBar from "../Components/Number bar/NumberBar";
import Footer from "../Components/lib/Footer/Footer";
import Events from "../Components/Home/Events";
import OurImpact from "../Components/Home/OurImpact";
import Ebos from "../Components/Home/Ebos";
import Portfolio from "../Components/Home/Portfolio";
import Retailers from "../Components/Home/Retailers";
import styled from "styled-components";
import Mastermind from "../Assets/masterminds.jpeg";
import Marketing from "../Assets/marketing.jpeg";
import Networks from "../Assets/networks.jpeg";
import Warehouse from "../Assets/warehouse.jpeg";
const WhyChooseUs = [
  {
    id: 0,
    title: "Masterminds Of Brand Building",
    imageUrl: Mastermind,
    content: [
      "At TARZ, we are more than just distributors; we are brand architects.",
      "Our expertise lies in crafting narratives that elevate brands to their maximum potential.",
      "We use strategic marketing and tailored campaigns to bring brands to life.",
      "Ensuring brands resonate with their target audience.",
      "Helping brands stand out in a crowded marketplace."
    ],
  },
  {
    id: 1,
    title: "Powerhouse Of PR & Marketing Expertise",
    imageUrl: Marketing,
    content: [
      "We offer comprehensive solutions covering all channels.",
      "From traditional ATL and BTL to cutting-edge social media and influencer campaigns.",
      "Our approach focuses on creating connections that drive results.",
      "Choose us for marketing services that propel your brand to new heights."
    ],
  },
  {
    id: 2,
    title: "Robust Distribution Networks",
    imageUrl: Networks,
    content: [
      "We pride ourselves on a meticulously crafted distribution network and strong retail partnerships.",
      "Seamlessly covering modern trade, e-commerce, and general retail trade sectors.",
      "Channels curated to cater to diverse market needs.",
      "Ensuring your brand reaches its audience promptly and efficiently.",
      "Enhancing visibility and maximizing market penetration.",
      "Leveraging technology and best practices to minimize lead times.",
      "Trust us to deliver your products swiftly and reliably, no matter the location."
    ],
  },
  {
    id: 3,
    title: "Top Of The Line Warehousing & Logistics",
    imageUrl: Warehouse,
    content: [
      "Our air-conditioned warehouses uphold the highest quality standards.",
      "We maintain optimal conditions to preserve product integrity and freshness.",
      "Adhering to international storage standards for secure environments.",
      "Our logistics network covers nearly all pin codes in India.",
      "Ensuring reliable and prompt delivery to every location.",
      "Trust us for careful warehousing and timely delivery, nationwide."
    ],
  },
];

const Home = ({ data }) => {
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [modalContent, setModalContent] = useState(null);

  const handleCardHover = (title, content, event) => {
    const cardRect = event.target.getBoundingClientRect();
    setModalPosition({
      top: cardRect.top,
      left: cardRect.left,
      width: cardRect.width,
      height: cardRect.height,
    });
    setModalContent({ content });
  };

  const headerRefs = useRef([]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.animateHeader);
          } else {
            entry.target.classList.remove(styles.animateHeader);
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentRefs = headerRefs.current;
    currentRefs.forEach((ref) => ref && observer.observe(ref));

    return () => currentRefs.forEach((ref) => ref && observer.unobserve(ref));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setModalContent(null);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const setRef = (index) => (el) => {
    headerRefs.current[index] = el;
  };

  return (
    <main className={styles.main}>
      {/* Hero Section */}
      <section id="hero" className={styles.heroSection}>
        {data?.Hero?.[0]?.media?.[0] ? (
          /\.(mp4|webm|ogg)$/i.test(data.Hero[0].media[0]) ? (
            <video
              className={styles.heroVideo}
              autoPlay
              loop
              muted
              controls
              controlsList="nodownload"
            >
              <source src={data.Hero[0].media[0]} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              className={styles.heroImage}
              src={data.Hero[0].media[0]}
              alt="Hero Media"
            />
          )
        ) : (
          <p>No media available</p>
        )}
      </section>

      <div className={styles.container}>
        {/* About Us Section */}
        <section  id="about-us" className={styles.homeSection}>
          <h2 ref={setRef(1)}>About Us</h2>
          <AboutContent>
            Nestled in Mumbai’s bustling heart and strategically positioned across India, TARZ Distribution India
            Pvt. Ltd. has been the premier partner for luxury and lifestyle beauty since 2007. With over 60 years
            of Chalhoub Group expertise, we excel in India&#39;s luxury beauty sector, shaping brands with
            dedication for over 15 years.
          </AboutContent>
          <br />
          <AboutContent>
            At TARZ, we are unrivalled brand builders. Our success is driven by passionate teams, reflected in
            our portfolio featuring Dior, Givenchy, and Burberry, and our dedicated staff.
          </AboutContent>
          <br />
          <AboutContent>
            Combining expertise with an innate understanding of luxury, TARZ stands out in today&#39;s market. Our
            nationwide presence, robust infrastructure, and committed workforce ensure excellence.
          </AboutContent>
          <br />
          <AboutContent>
            Join us at TARZ Distribution India Pvt. Ltd. – where luxury meets expertise, and success is
            a shared pursuit.
          </AboutContent>
        </section>

        <NumberBar data={data.Number_Bar} />

        {/* Our Portfolio Section */}
        <section
          id="OurPortfolio"
          className={`${styles.homeSection} ${styles.customBackground}`}
        >
          <h2 ref={setRef(2)}>Our Portfolio</h2>
          <Portfolio data={data.Portfolio} />
          <div id="Retailers"></div>
        </section>

        {/* Retailers Section */}
        <section
          className={`${styles.homeSection} ${styles.customBackground}`}
        >
          <h2 ref={setRef(3)}>Retailers</h2>
          <Retailers data={data.Retailer} />
        </section>

        {/* Why Choose Us Section */}
        <section id="why-choose-us" className={styles.homeSection}>
          <TopImage src="/leaf_bg.jpg" />
          <h2 ref={setRef(4)}>Why Choose Us:</h2>
          <div className={styles.cardsContainer}>
            {WhyChooseUs.map((item) => (
              <div className={styles.why__choose__us} key={item.id}>
                <div
                  className={styles.card}
                  onMouseEnter={(event) =>
                    handleCardHover(item.title, item.content, event)
                  }
                >
                  <img
                    src={item.imageUrl}
                    alt={item.title}
                    className={styles.cardImage}
                  />
                </div>
                {modalContent && (
                  <div
                    className={styles.modalOverlay}
                    style={{
                      top: modalPosition.top,
                      left: modalPosition.left,
                      width: modalPosition.width,
                      height: modalPosition.height,
                    }}
                  >
                    <div className={styles.modalContent}>
                      {modalContent.content.map((point, index) => (
                        <p key={index}>• {point}</p>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <DownImage src="/leaf_bg.jpg" />
        </section>

        {/* Boutiques Section */}
        <section
          id="ebos"
          className={`${styles.homeSection} ${styles.customBackground}`}
        >
          <h2 ref={setRef(5)}>Boutiques</h2>
          <Ebos data={data.EBOS} />
        </section>

        {/* Events Section */}
        <section
          id="events"
          className={`${styles.homeSection} ${styles.customBackground}`}
        >
          <h2 ref={setRef(6)}>Events</h2>
          <Events data={data.Events} />
        </section>

        {/* Our Impact Section */}
        <section id="our-impact" className={styles.homeSection}>
          <TopImage src="/leaf_bg.jpg" />
          <h2 ref={setRef(7)}>Our Impact</h2>
          <OurImpact data={data.Our_Impact} />
          <DownImage src="/leaf_bg.jpg" />
        </section>
      </div>

      <Footer />
    </main>
  );
};

export default Home;

const TopImage = styled.img`
  transform: scaleX(-1);
  position: absolute;
  top: 0;
  left: 0;
  width: 10%;
  z-index: 1;
  mix-blend-mode: multiply;

  @media screen and (max-width: 576px) {
    width: 30%;
  }
`;

const DownImage = styled.img`
  transform: scaleY(-1) translateY(-80px);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10%;
  z-index: 1;
  mix-blend-mode: multiply;

  @media screen and (max-width: 576px) {
    width: 30%;
  }
`;

const AboutContent = styled.p`
  margin: 0 !important;
  font-size: 18px !important;
  color: #666 !important;
  padding: 0 70px !important;
  text-align: justify;

  @media screen and (max-width: 992px) {
    padding: 0 40px !important;
    font-size: 16px !important;
  }
  @media screen and (max-width: 576px) {
    padding: 0 20px !important;
    font-size: 12px !important;
  }
`;
