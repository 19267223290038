import React, { useState } from 'react';
import Modal from '../lib/Modal';
import InfoCarousel from '../lib/InfoCarousel';
import styled from 'styled-components';

const Ebos = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMedia, setCurrentMedia] = useState({});

  const handleVideoClick = (video) => {
    setCurrentMedia(video);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentMedia({});
  };

  return (
    <EbosConatiner>
      <InfoCarousel items={data} onMediaClick={handleVideoClick} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          mediaSrc={currentMedia.media}
          title={currentMedia.title}
          description={currentMedia.description}
          type={currentMedia.type}
          map={currentMedia.map}
        />
      )}
    </EbosConatiner>
  );
};

const EbosConatiner = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 20px;
`;

export default Ebos;
