import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import "./Navbar.css";

import logo from "../../Assets/images/terz_logo.svg";
import GreatPlaceToWork from "../../../Assets/gptw-logo.png";

const Navbar = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [animate, setAnimate] = useState(false);

  // Trigger the animation automatically after a set time
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 500); // Change 3000 to however many milliseconds you want

    return () => clearTimeout(timer); // Clean up the timer if the component unmounts
  }, []); // Empty dependency array means this effect runs once on mount

  // Toggle the visibility of the menu
  const toggleMenu = () => {
    setIsMenuVisible((prevState) => !prevState); // Toggle based on previous state
  };

  // Handle scroll to section and close menu
  const handleScrollToSection = (sectionId, event) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setIsMenuVisible(false); // Close the menu after scrolling
    }
  };

  return (
    <div className={`navbar ${animate ? "defaultNav" : "initialNav"}`}>
      <div className="gptw-logo">
        <img className={`${animate ? "gptw-img": "gptw-none"}`} src={GreatPlaceToWork} alt="" />
      </div>
      <div className="nav-logo">
        {/* Removed onClick handler from the logo container */}
        <a
          href="#home"
          className={`logo-container ${animate ? "slide" : "hideElse"}`}
          onClick={(e) => handleScrollToSection("hero", e)}
        >
          <img src={logo} alt="Logo" className="logo" />
        </a>
        {/* Removed Click to Start text as it's now irrelevant */}
      </div>
      <FaBars className="hamburger-menu" onClick={toggleMenu} />
      <div className={`nav-menu ${isMenuVisible ? "active" : ""}`}>
        <ul>
          <li onClick={(e) => handleScrollToSection("about-us", e)}>
            ABOUT US
          </li>
          <li onClick={(e) => handleScrollToSection("OurPortfolio", e)}>
            OUR PORTFOLIO
          </li>
          <li onClick={(e) => handleScrollToSection("Retailers", e)}>
            RETAILERS
          </li>
          <li onClick={(e) => handleScrollToSection("why-choose-us", e)}>
            WHY CHOOSE US
          </li>
          <li onClick={(e) => handleScrollToSection("ebos", e)}>BOUTIQUES</li>
          <li onClick={(e) => handleScrollToSection("events", e)}>EVENTS</li>
          <li onClick={(e) => handleScrollToSection("our-impact", e)}>
            OUR IMPACT
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
