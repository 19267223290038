import React from "react";
import Carousel from "../lib/Carousel";
import styled from "styled-components";

const Portfolio = ({ data }) => {
  return (
    <PortfolioConatiner>
      <Carousel cardType="brand" items={data} />
    </PortfolioConatiner>
  );
};

const PortfolioConatiner = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 20px;
`;

export default Portfolio;
