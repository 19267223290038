import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import InfoCard from "./InfoCard";
import BrandCard from "./BrandCard";

const Carousel = ({ items, onMediaClick, padding, cardType }) => {
  // Duplicate items if there are fewer than 4
  const filledItems = items.length < 4 ? [...items, ...items.slice(0, 4 - items.length)] : items;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    adaptiveHeight: true,
    pauseOnHover: false,
    responsive: [
      { breakpoint: 1290, settings: { slidesToShow: 3 } },
      { breakpoint: 992, settings: { slidesToShow: 2 } },
      { breakpoint: 600, settings: { slidesToShow: 1 } },
    ],
  };

  const playVideo = (item) => {
    if (
      item.media &&
      item.media.length > 0 &&
      (item.media[0].endsWith(".mp4") || item.media[0].endsWith(".webm"))
    ) {
      const video = document.getElementById(`video-${item._id}`);
      if (video) {
        video.play();
      }
    }
  };

  return (
    <CarouselContainer>
      <Slider {...settings}>
        {filledItems.map((item, index) => (
          <React.Fragment key={index}>
            {cardType === "brand" && item.media && item.media.length > 0 ? (
              <BrandCard
                key={item._id}
                imageUrl={item.media[0]}
                alt={item.title}
                onClick={() => onMediaClick(item)}
              />
            ) : (
              <InfoCardContainer
                key={item._id}
                onClick={() => onMediaClick(item)}
              >
                <InfoCard
                  padding={padding}
                  onMouseEnter={() => playVideo(item)}
                >
                  <MediaContainer>
                    {item.media && item.media.length > 0 ? (
                      item.media[0].endsWith(".mp4") ||
                      item.media[0].endsWith(".webm") ? (
                        <CarouselVideo
                          id={`video-${item._id}`}
                          src={item.media[0]}
                          muted
                          loop
                          className="carousel-media"
                          onMouseEnter={(e) => e.currentTarget.play()}
                          onMouseLeave={(e) => e.currentTarget.pause()}
                        />
                      ) : (
                        <CarouselImage
                          src={item.media[0]}
                          alt={item.title}
                          className="carousel-media"
                        />
                      )
                    ) : (
                      <p>No media found for this item.</p>
                    )}
                  </MediaContainer>
                  <hr />
                  <ContentContainer>
                    <CardTitle>{item.title}</CardTitle>
                  </ContentContainer>
                </InfoCard>
              </InfoCardContainer>
            )}
          </React.Fragment>
        ))}
      </Slider>
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const InfoCardContainer = styled.div`
  cursor: pointer;

  hr {
    margin-top: 10px;
  }
`;

const MediaContainer = styled.div`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  height: 195px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    max-height: 195px; 
  }

  @media screen and (max-width: 576px) {
    max-height: 150px; 
  }
`;

const CarouselVideo = styled.video`
  width: 100%;
  height: 195px;
  border-radius: 20px;
  transition: transform 0.3s ease-in-out;

  @media screen and (max-width: 1200px) {
    max-height: 195px; 
  }
  
  @media screen and (max-width: 576px) {
    max-height: 150px; 
  }
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 195px;
  border-radius: 20px;
  transition: transform 0.3s ease-in-out;


  @media screen and (max-width: 1200px) {
    max-height: 195px; 
  }

  @media screen and (max-width: 576px) {
    max-height: 150px; 
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

const CardTitle = styled.p`
  border: 1.8px solid black;
  width: 80%;
  padding: 20px 0 !important;
  border-radius:20px;
  font-size: 17px !important;
  
  @media screen and (max-width: 576px) {
    font-size: 14px !important;
    padding: 20px 8px !important;
  }
`;

export default Carousel;
