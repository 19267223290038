import React, { useEffect, useState, useRef, useCallback } from "react";
import styled, { keyframes } from "styled-components";
import {
  FaVolumeMute,
  FaVolumeUp,
  FaAngleLeft,
  FaAngleRight,
  FaSpinner,
  FaTimesCircle,
} from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";

const Modal = ({
  isOpen,
  mediaSrc,
  title,
  description,
  onClose,
  type,
  map,
}) => {
  const [isMuted, setIsMuted] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [shouldDisplayButton, setShouldDisplayButton] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    const descriptionElement = descriptionRef.current;
    if (descriptionElement) {
      const isOverflowing =
        descriptionElement.scrollHeight > descriptionElement.clientHeight;
      setShouldDisplayButton(isOverflowing);
    }
  }, [description]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaSrc.length);
  }, [mediaSrc.length]);

  const goToPrevious = useCallback(() => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + mediaSrc.length) % mediaSrc.length
    );
  }, [mediaSrc.length]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "auto";

    const handleKey = (e) => {
      if (e.key === "ArrowRight") goToNext();
      if (e.key === "ArrowLeft") goToPrevious();
    };

    window.addEventListener("keydown", handleKey);

    return () => {
      window.removeEventListener("keydown", handleKey);
      document.body.style.overflow = "auto";
    };
  }, [isOpen, goToNext, goToPrevious]);

  if (!isOpen) return null;

  const currentMedia = mediaSrc[currentIndex];
  const isVideo = currentMedia?.endsWith(".mp4");
  const shouldDisplayNavigation = mediaSrc.length > 1;

  const toggleMute = () => {
    if (isVideo) {
      const currentMuteState = !videoRef.current.muted;
      videoRef.current.muted = currentMuteState;
      setIsMuted(currentMuteState);
    }
  };

  const handleVideoLoadStart = () => setIsLoading(true);
  const handleVideoLoadedData = () => setIsLoading(false);

  return (
    <ModalOverlay onClick={onClose}>
      <ModalBody onClick={(e) => e.stopPropagation()}>
        <ModalCloseBtn onClick={onClose}>
          <FaTimesCircle />
        </ModalCloseBtn>
        <ModalFLex>
          <ModalMedia>
            {isVideo ? (
              <>
                {isLoading && <Spinner />}
                <ModalVideo
                  ref={videoRef}
                  src={currentMedia}
                  autoPlay
                  muted={isMuted}
                  onLoadStart={handleVideoLoadStart}
                  onLoadedData={handleVideoLoadedData}
                />
              </>
            ) : (
              <ModalImage src={currentMedia} alt="Media content" />
            )}
            <>
              {shouldDisplayNavigation && (
                <NavigationControls>
                  <NavBtn onClick={goToPrevious}>
                    <NavBtnContainer>
                      <FaAngleLeft />
                    </NavBtnContainer>
                    <NavBtn onClick={goToNext}></NavBtn>
                    <NavBtnContainer>
                      <FaAngleRight />
                    </NavBtnContainer>
                  </NavBtn>
                </NavigationControls>
              )}
            </>
            {isVideo && (
              <MuteBtn onClick={toggleMute}>
                {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
              </MuteBtn>
            )}
          </ModalMedia>
          <ModalContent>
            <ItemTitle>{title}</ItemTitle>
            <DescriptionContainer>
              <ItemDescriptionText expanded={expanded} ref={descriptionRef}>
                {description}
              </ItemDescriptionText>
            </DescriptionContainer>
            <div>
              {shouldDisplayButton && (
                <ViewMoreOverlay>
                  <ViewMoreButton onClick={toggleExpanded}>
                    {expanded ? "View less" : "View more"}
                  </ViewMoreButton>
                </ViewMoreOverlay>
              )}
            </div>
            {map && (
              <OpenInMapsButton onClick={() => window.open(map, "_blank")}>
                <CiLocationOn color="#fff" />
                Open in Maps
              </OpenInMapsButton>
            )}
          </ModalContent>
        </ModalFLex>
      </ModalBody>
    </ModalOverlay>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const scaleIn = keyframes`
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: -webkit-fill-available;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: ${fadeIn} 0.5s ease-out forwards;
`;

const ModalBody = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  width: 90%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  animation: ${scaleIn} 0.5s ease-out forwards;

  @media screen and (max-width: 1024px) {
    height: max-content;
    padding-bottom: 20px;
  }
`;

const ModalFLex = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: space-between;
  }
`;

const ModalVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;

  @media screen and (max-width: 1024px) {
    height: 400px;
    top: 0;
  }

  @media screen and (max-width: 576px) {
    height: 250px;
    top: 0;
  }
`;

const ModalImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;

  @media screen and (max-width: 1024px) {
    height: 350px;
    top: 0;
  }

  @media screen and (max-width: 576px) {
    height: 250px;
    top: 0;
  }
`;

const ModalMedia = styled.div`
  width: 60%;
  height: max-content;
  position: relative;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const NavigationControls = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  bottom: 50%;
  width: 100%;
  align-items: center;

  @media screen and (max-width: 1024px) {
    bottom: 50%;
  }
`;

const NavBtn = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 576px) {
    font-size: 16px;
  }
`;

const NavBtnContainer = styled.div`
  padding: 10px;
  background: hsla(0, 0%, 100%, 0.6);
  display: flex;
`;

const MuteBtn = styled.button`
  position: absolute;
  left: 20px;
  top: 20px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 999px;
  background: hsla(0, 0%, 100%, 0.6);
  color: #333;
  font-size: 24px;
  z-index: 10;
  display: flex;

  @media screen and (max-width: 576px) {
    font-size: 16px;
    padding: 6px;
  }
`;

const ModalCloseBtn = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #333;
  z-index: 99;
`;

const Spinner = styled(FaSpinner)`
  color: #fff;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalContent = styled.div`
  width: 40%;
  overflow-y: auto;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1024px) {
    width: 80%;
    height: 70%;
  }

  @media screen and (max-width: 576px) {
    width: 80%;
    height: 100%;
  }
`;

const DescriptionContainer = styled.div`
  position: relative;
`;

const ItemDescriptionText = styled.p`
  color: #666;
  text-align: justify;
  hyphens: auto;
  font-size: 18px !important;
  max-height: ${({ expanded }) => (expanded ? "70vh" : "60vh")};
  overflow-y: ${({ expanded }) => (expanded ? "scroll" : "hidden")};
  
  @media screen and (max-width: 1024px) {
    max-height: ${({ expanded }) => (expanded ? "50vh" : "45vh")};
    font-size: 16px !important;
  }
  
  @media screen and (max-width: 576px) {
    max-height: ${({ expanded }) => (expanded ? "30vh" : "27vh")};
    font-size: 12px !important;
  }
`;

const ViewMoreOverlay = styled.div`
  position: relative;
  padding: 5px;
`;

const ViewMoreButton = styled.button`
  background: #007bff;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 999px;
`;

const ItemTitle = styled.h2`
  margin: 20px 0;
  font-size: 36px !important;
  font-weight: bold;
  text-align: center;
  color: #333;

  @media screen and (max-width: 1024px) {
    font-size: 24px !important;
  }
`;

const OpenInMapsButton = styled.button`
  background: #007bff;
  border: none;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 50%;
  font-size: 24px;
  padding: 10px 20px;
  border-radius: 999px;
  margin-top: 20px;

  @media screen and (max-width: 1024px) {
    width: 90%;
    font-size: 16px;
    margin-top: 10px;
  }
`;

export default Modal;
