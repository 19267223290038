import React, { useState } from 'react';
import Modal from '../lib/Modal';
import InfoCarousel from '../lib/InfoCarousel';
import styled from 'styled-components';

const OurImpact = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMedia, setCurrentMedia] = useState({});
  
  const handleVideoClick = (video) => {
    setCurrentMedia(video);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentMedia({});
  };

  return (
    <OurImpactConatiner>
      <InfoCarousel items={data} onMediaClick={handleVideoClick} />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          mediaSrc={currentMedia.media}
          title={currentMedia.title} // Title is displayed in the modal now
          description={currentMedia.description}
        />
      )}
    </OurImpactConatiner>
  );
};

const OurImpactConatiner = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 20px;
`;

export default OurImpact;
