import React from "react";
import Carousel from "../lib/Carousel";
import styled from "styled-components";

const Retialers = ({ data }) => {
  return (
    <RetialersConatiner>
      <Carousel cardType="brand" items={data} />
    </RetialersConatiner>
  );
};

const RetialersConatiner = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 20px;
`;

export default Retialers;
