import React from 'react';
import styled from 'styled-components';

const InfoCard = ({ children, padding }) => {
  return (
    <InfoCardContainer padding={padding}>
      {children}
    </InfoCardContainer>
  );
};

const InfoCardContainer = styled.div`
  border-radius: 30px;
  padding: ${props => props.padding ? props.padding : '40px'};
  width: -webkit-fill-available;
  border: 1px solid #000;
`;

export default InfoCard;
