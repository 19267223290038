// src/hooks/useCountUp.js
import { useState, useEffect } from 'react';

const useCountUp = (start, end, duration) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    let frame = 0;
    const totalFrames = Math.round((duration / 1000) * 60); // Calculate based on 60 fps
    let currentCount = start;

    const step = (timestamp) => {
      if (currentCount < end) {
        frame++;
        const progress = frame / totalFrames;
        currentCount = start + Math.round((end - start) * progress);

        // Ensure the count does not exceed the end value
        if (currentCount > end) {
          currentCount = end;
        }

        setCount(currentCount);

        if (progress < 1) {
          requestAnimationFrame(step);
        }
      }
    };

    requestAnimationFrame(step);

    // Cleanup function to cancel the animation if the component unmounts
    return () => {
      frame = totalFrames; // Ensures animation stops if component unmounts
    };
  }, [start, end, duration]);

  return count;
};

export default useCountUp;
