import React from "react";
import styled from "styled-components";

const BrandCard = ({ imageUrl, alt }) => {
  return (
    <BrandMain>
      <BrandCardContainer>
        <BrandItemImage src={imageUrl} alt={alt} />
      </BrandCardContainer>
    </BrandMain>
  );
};

const BrandMain = styled.div`
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 992px) {
    padding: 10px 0;
  }
`;

const BrandCardContainer = styled.div`
  outline: none;
  position: relative;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  border-radius: 18px; /* Rounded corners for a polished look */
  overflow: hidden; /* Clips the content to fit the border radius */
  transition: box-shadow 0.3s ease; /* Smooth transition for shadow effects */
  width: 100%;
  height: auto;
  /* aspect-ratio: 1/1; */

  /* Enhance the shadow on hover to give a lifting effect */
  &:hover {
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    margin: 10px; /* Less margin for smaller screens */
    width: 80%;
  }

  @media (max-width: 480px) {
    margin: 5px; /* Minimal margin for very small screens */
  }
`;

const BrandItemImage = styled.img`
  width: 100%; /* Make the image fill the entire width of its container */
  height: auto; /* Maintain the aspect ratio */
  border-radius: 8px;
  transition: transform 0.3s ease-in-out; /* Smooth transition for transform effects */

  /* Scale up the image on hover within its card-like container */
  ${BrandCardContainer}:hover & {
    transform: scale(1.03);
  }

  @media (max-width: 768px) {
    /* Optionally disable the scale effect on touch devices */
    transition: none;
  }
`;

export default BrandCard;
